var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import Jivo from '@/shared/utils/JivoChat.utils';
import FreeAccountNotificationBar from './FreeAccountNotificationBar.vue';
let FreeAccountNotificationBarContainer = class FreeAccountNotificationBarContainer extends Vue {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.dispose = () => { };
        this.userPlanInformation = {
            username: '',
            end_date: '',
            plan_name: '',
            subDomainEmailUser: '',
        };
    }
    async mounted() {
        //@ts-ignore
        // await this.actions.getUserInformation()
        // //@ts-ignore
        // await this.actions.getUserMe()
        // //@ts-ignore
        // await this.actions.setLocalStorageUserPlanInformation()
        // this.getPlanInformation()
    }
    getPlanInformation() {
        this.userPlanInformation = {
            username: localStorage.getItem('user_name_logged'),
            end_date: localStorage.getItem('end_date_plan_user'),
            plan_name: localStorage.getItem('user_plan_name'),
            subDomainEmailUser: localStorage.getItem('sub_domain_user_logged'),
        };
    }
    showJivoMessage(click) {
        const msg = 'Deseja assinar conosco? Responda com "Alterar Plano"';
        if (click) {
            // @ts-ignore
            window.jivo_api.open();
            setTimeout(() => {
                Jivo.getWelcomeMsg(msg);
            }, 20);
        }
    }
    beforeDestroy() {
        this.dispose();
        // @ts-ignore
        window.jivo_api.close();
        const jivoContainer = Jivo.getJivoContainer();
        let welcomeDiv = null;
        if (jivoContainer) {
            welcomeDiv = jivoContainer.querySelector('#jivo-container--welcome');
            if (welcomeDiv)
                welcomeDiv.remove();
        }
    }
};
FreeAccountNotificationBarContainer = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.users,
    }), {
        name: 'FreeAccountNotificationBarContainer',
        components: {
            FreeAccountNotificationBar,
        },
    }))
], FreeAccountNotificationBarContainer);
export default FreeAccountNotificationBarContainer;
