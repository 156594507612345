var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
let FreeAccountNotificationBar = class FreeAccountNotificationBar extends Vue {
    constructor() {
        super(...arguments);
        this.message = '';
        this.showNotify = true;
    }
    mounted() {
        setTimeout(() => {
            this.notifyMessage();
        }, 1000);
    }
    observer(curr) {
        if (curr)
            this.notifyMessage();
    }
    notifyMessage() {
        const diffenceDay = this.differenceBetweenDay(new Date(), new Date(this.userPlanInformation.end_date));
        if (this.checkEmailDomainIsClient &&
            this.userPlanInformation.plan_name === 'ATLAS FREE') {
            this.showNotify = true;
            return diffenceDay > 0
                ? // @ts-ignore
                    (this.message = `Olá, ${this.userPlanInformation.username} ! Você tem mais ${diffenceDay} dia em seu Teste de Avaliação.`)
                : // @ts-ignore
                    (this.message = `Olá, ${this.userPlanInformation.username}! Hoje finaliza seu Teste de Avaliação.`);
        }
        this.showNotify = false;
    }
    differenceBetweenDay(dateInitial, dateFinal) {
        const time_difference = dateFinal.getTime() - dateInitial.getTime();
        return Math.floor(time_difference / (1000 * 60 * 60 * 24));
    }
    get checkEmailDomainIsClient() {
        return this.userPlanInformation.subDomainEmailUser !== 'speediomail.com';
    }
    signNow() {
        return true;
    }
};
__decorate([
    Prop({ default: () => { } })
], FreeAccountNotificationBar.prototype, "userPlanInformation", void 0);
__decorate([
    Watch('userPlanInformation', { deep: true, immediate: true })
], FreeAccountNotificationBar.prototype, "observer", null);
__decorate([
    Emit('signNow')
], FreeAccountNotificationBar.prototype, "signNow", null);
FreeAccountNotificationBar = __decorate([
    Component({
        name: 'FreeAccountNotificationBar',
    })
], FreeAccountNotificationBar);
export default FreeAccountNotificationBar;
