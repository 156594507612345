var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import NotificationsDropdown from 'components/notifications/NotificationsDropdown.vue';
import { connect } from '@/overmind';
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import { json } from 'overmind';
import { openDownloadExportFile } from '@/shared/utils/helpers';
import { removeAccentInName } from '@/shared/utils/helpers';
import { changeSymbolsByDashesInTheName } from '@/shared/utils/helpers';
import { Notify } from 'quasar';
let NotificationsContainer = class NotificationsContainer extends Vue {
    constructor() {
        super(...arguments);
        this.currentPage = 1;
        this.showMenu = false;
        this.notifications = [];
    }
    async getNotifications() {
        // @ts-ignore
        await this.actions.getNotifications({ page: this.currentPage });
    }
    async updatePaginate(page) {
        if (this.currentPage < page) {
            this.currentPage++;
            await this.getNotifications();
            this.notifications = this.notIgnoredNotifications();
        }
    }
    notIgnoredNotifications() {
        // @ts-ignore
        const { state } = this;
        let notifications;
        if (state.notifications) {
            const notifications_ = state.notifications.filter(n => !state.ignoredNotifications.includes(n._id));
            notifications = notifications_;
        }
        return json(notifications);
    }
    async onScroll(scrollPosition) {
        const documentListNotificationsDropdown = document.getElementById('id_list_notifications_dropdown') || { offsetHeight: 1 };
        const a = Math.round(documentListNotificationsDropdown.offsetHeight / 5);
        const page = Math.ceil(scrollPosition.valueOf() / a);
        this.updatePaginate(page);
    }
    async onRedirect(notification) {
        // @ts-ignores
        // this.actions.markNotificationsAsRead(notification)
        // if (!isEmpty(notification.context)) {
        //   this.$router
        //     .push({
        //       name: 'infoset',
        //       params: { infosetId: notification.context.infoset.id },
        //     })
        //     .catch(err => err)
        //     .finally(() => {
        //       this.$emit('close')
        //     })
        // }
        if (notification.context &&
            notification.context.event &&
            notification.context.event.id) {
            let filePath = (notification.context.filesUrl || '').split('./exportations/')[1] +
                changeSymbolsByDashesInTheName(removeAccentInName(notification.context.infoset.name.substring(0, 30))) +
                this.fileExtension(notification.context.service || '', notification.context.event ? notification.context.event.rows : 0);
            if (this.fileExtension(notification.context.service || '', notification.context.event ? notification.context.event.rows : 0) == '.zip')
                filePath = filePath.replaceAll('-01', '');
            openDownloadExportFile(filePath);
        }
    }
    fileExtension(service, rows) {
        if (rows && rows > 20000)
            return '.zip';
        if (service.match('csv'))
            return '.csv';
        return '.xlsx';
    }
    async openNotifications() {
        // @ts-ignore
        await this.getNotifications();
        // @ts-ignore
        this.actions.updateLocalNotifications();
        // @ts-ignore
        this.actions.verifyNewNotifications();
        this.hasNewNotifications();
        this.showMenu = true;
        this.notifications = this.notIgnoredNotifications();
    }
    created() {
        this.notifications = this.notIgnoredNotifications();
    }
    hasNewNotifications() {
        return true;
    }
    onIgnore(notification) {
        // @ts-ignores
        this.actions.markNotificationsAsRead(notification);
        // @ts-ignores
        this.actions.addLocalIgnoredNotifications(notification);
    }
    autoClose(event) {
        return event;
    }
    async onboardingConfirmation({ selectedOption, comment, }) {
        // @ts-ignore TS2349: property inexistent
        await this.actions.onboardingRegister({ comment, selectedOption });
    }
    async onSuggestSpeedmail() {
        // @ts-ignores
        await this.actions.suggestSpeedmail();
        Notify.create({
            message: 'Aguarde um de nossos consultores',
            type: 'success',
            timeout: 60000,
        });
    }
};
__decorate([
    Prop({ default: false })
], NotificationsContainer.prototype, "onboardingNotification", void 0);
__decorate([
    Emit('hasNewNotifications')
], NotificationsContainer.prototype, "hasNewNotifications", null);
__decorate([
    Emit('autoClose')
], NotificationsContainer.prototype, "autoClose", null);
NotificationsContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        state: state.users,
        ignoredNotifications: state.users.ignoredNotifications,
        showOnBoardingNotify: state.users.showOnBoardingNotify,
        downloadExportationFile: actions.infosets.downloadExportationFile,
        csAvatarUrl: state.users.currentUser.csAvatarUrl,
        userOrigin: state.users.currentUser.origin,
    }), {
        components: { NotificationsDropdown },
    }))
], NotificationsContainer);
export default NotificationsContainer;
