var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import NavBar from '@/components/NavBar/NavBar.vue';
import { Component, Emit, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { disableJivo } from '@/shared/utils/helpers';
let NavBarContainer = class NavBarContainer extends Vue {
    constructor() {
        super(...arguments);
        this.showNotificationsIconWithIndicator = true;
    }
    onDetails(notification) {
        return notification;
    }
    openMenu() {
        return true;
    }
    async created() {
        // @ts-ignore TS2349: property inexistent
        this.actions.getNotifications({ page: 1 });
        this.idSetInterval = setInterval(() => {
            // @ts-ignore TS2349: property inexistent
            this.actions.getNotifications({ page: 1 });
        }, 180000);
        // @ts-ignore TS2349: property inexistent
        await this.actions.showOnboardingNotification();
    }
    disableJivo() {
        // @ts-ignore TS2349: property inexistent
        if (this.usersState.currentUser)
            disableJivo(this.usersState.currentUser);
        // @ts-ignore TS2349: property inexistent
        else
            disableJivo(this.$auth.user());
    }
    mounted() {
        this.disableJivo();
    }
    beforeDestroy() {
        clearInterval(this.idSetInterval);
    }
};
__decorate([
    Emit('onDetails')
], NavBarContainer.prototype, "onDetails", null);
__decorate([
    Emit('openNotifications')
], NavBarContainer.prototype, "openMenu", null);
NavBarContainer = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.users,
        usersState: state.users,
        haveNewNotifications: state.users.haveNewNotifications,
        showOnBoardingNotify: state.users.showOnBoardingNotify,
    }), {
        components: {
            NavBar,
        },
    }))
], NavBarContainer);
export default NavBarContainer;
