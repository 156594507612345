var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import NotificationsContainer from 'components/notifications/Notifications.container.vue';
import IconList from '../Icons/icon-list.vue';
import IconShop from '../Icons/IconShop.vue';
let NavBar = class NavBar extends Vue {
    constructor() {
        super(...arguments);
        this.showNotifications = false;
    }
    get userSankhyaAllow() {
        return (
        // @ts-ignore
        this.$auth.check('prospector') &&
            // @ts-ignore
            this.$auth.user().email.match('@sankhya.com.br'));
    }
    get userIsNotProspector() {
        // @ts-ignore
        return !this.$auth.check('prospector');
    }
    get userIsAdminOrCS() {
        // @ts-ignore
        return this.$auth.check('admin') || this.$auth.check('customer_success');
    }
    get checkInfosetRoute() {
        return this.$route.path.includes('infosets');
    }
    get checkSearchRoute() {
        return this.$route.path.includes('search');
    }
    get routIsMgmPanel() {
        return this.$route.fullPath.includes('user/share-and-win') ? true : false;
    }
    toggleNotificationsMenu() {
        this.showNotifications = !this.showNotifications;
    }
    onDetails(notification) {
        return notification;
    }
    openMenu() {
        return true;
    }
};
__decorate([
    Prop({ type: Boolean, default: true })
], NavBar.prototype, "showNotificationsIconWithIndicator", void 0);
__decorate([
    Prop({ default: false })
], NavBar.prototype, "showOnBoardingNotify", void 0);
__decorate([
    Emit('onDetails')
], NavBar.prototype, "onDetails", null);
__decorate([
    Emit('openNotifications')
], NavBar.prototype, "openMenu", null);
NavBar = __decorate([
    Component({
        name: 'NavBar',
        components: {
            NotificationsContainer,
            IconList,
            IconShop,
        },
    })
], NavBar);
export default NavBar;
