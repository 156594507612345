var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { date } from 'quasar';
import { isEmpty } from 'lodash';
let NotificationsDropdownItem = class NotificationsDropdownItem extends Vue {
    constructor() {
        super(...arguments);
        this.hovering = false;
        this.hasContext = false;
    }
    create() {
        this.hasContext = !isEmpty(this.notification);
    }
    onMouseover(value) {
        this.hovering = value;
    }
    onIgnore() {
        this.$emit('onIgnore', this.notification);
    }
    onDetails(notification) {
        this.$emit('onDetails', notification);
    }
    get notificationLabelDetailsButton() {
        if (this.notification.context.service) {
            if (this.notification.context.service.includes('excel') ||
                this.notification.context.service.includes('csv')) {
                return 'Baixar arquivos';
            }
        }
        return 'Detalhes';
    }
    get notificationIconDetailsButton() {
        if (this.notification.context.service) {
            if (this.notification.context.service.includes('excel') ||
                this.notification.context.service.includes('csv')) {
                return 'file_download';
            }
        }
        return 'visibility';
    }
};
__decorate([
    Prop({ required: true })
], NotificationsDropdownItem.prototype, "notification", void 0);
NotificationsDropdownItem = __decorate([
    Component({
        name: 'NotificationsDropdownItem',
        filters: {
            formatDateBRL(value) {
                return date.formatDate(value, 'DD/MM/YYYY');
            },
        },
    })
], NotificationsDropdownItem);
export default NotificationsDropdownItem;
