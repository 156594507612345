var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import NotificationsDropdownItem from '@/components/notifications/NotificationsDropdownItem.vue';
import NotificationCardItem from '@/components/notifications/NotificationsCardItem.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let NotificationsDropdown = class NotificationsDropdown extends Vue {
    constructor() {
        super(...arguments);
        this.teste = true;
        this.showMenu = true;
    }
    scrolling(event) {
        return event;
    }
    onChangeMenu() {
        return true;
    }
    onDetails(notification) {
        return notification;
    }
    getHeightModal() {
        const offsetWidthRef = this.$refs['id_list_notifications_dropdown'];
        return offsetWidthRef;
    }
    onIgnore(notification) {
        return notification;
    }
    autoClose(event) {
        return event;
    }
    onSuggestSpeedmail() {
        return;
    }
    handleRandomCalendyUrls() {
        const urls = [
            'https://speedio.com.br/agenda/gabriel-luz/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ0rnU3pM6P-Kjwz_Uc7wyxAeDu-w_Z4tqoKWvU=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3E90BF5U479E50umiv2firopy3PL0H2QxOKkA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2cv13NF5wHfCqEqTQHDuWu8_zLlpQ4Vbn5KCU=',
            'https://speedio.com.br/agenda/matheus-miranda/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3rNPBRX37KGdMKl4PIpCtiypLOnd64_jJcqzA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2nDjuw4ibkwYlJzzJp4Ln2-gPjcuLxUlGTdCY=',
        ];
        const redirectTo = urls[this.handleRandomRange(0, urls.length)];
        window.open(redirectTo, '_blank');
    }
    handleRandomRange(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    onboardingConfirmation() {
        this.handleRandomCalendyUrls();
        this.trackingInternalOnboarding();
        return { selectedOption: 'scheduled', comment: '' };
    }
    trackingInternalOnboarding() {
        return mixpanelTracking('onboarding: Clicou em agendar Onboarding');
    }
    trackingNotifications() {
        return mixpanelTracking('notificações: Abriu as notificações');
    }
    mounted() {
        this.trackingNotifications();
    }
};
__decorate([
    Prop({ default: () => [] })
], NotificationsDropdown.prototype, "notifications", void 0);
__decorate([
    Prop({ default: false })
], NotificationsDropdown.prototype, "show", void 0);
__decorate([
    Prop({ default: false })
], NotificationsDropdown.prototype, "onboardingNotification", void 0);
__decorate([
    Prop({ default: '' })
], NotificationsDropdown.prototype, "csAvatar", void 0);
__decorate([
    Prop({ default: '' })
], NotificationsDropdown.prototype, "userOrigin", void 0);
__decorate([
    Emit('scroll')
], NotificationsDropdown.prototype, "scrolling", null);
__decorate([
    Emit('open')
], NotificationsDropdown.prototype, "onChangeMenu", null);
__decorate([
    Emit('onDetails')
], NotificationsDropdown.prototype, "onDetails", null);
__decorate([
    Emit('onIgnore')
], NotificationsDropdown.prototype, "onIgnore", null);
__decorate([
    Emit('autoClose')
], NotificationsDropdown.prototype, "autoClose", null);
__decorate([
    Emit('onSuggestSpeedmail')
], NotificationsDropdown.prototype, "onSuggestSpeedmail", null);
__decorate([
    Emit('onboardingConfirmation')
], NotificationsDropdown.prototype, "onboardingConfirmation", null);
NotificationsDropdown = __decorate([
    Component({
        name: 'NotificationsDropdown',
        components: {
            NotificationsDropdownItem,
            NotificationCardItem,
        },
    })
], NotificationsDropdown);
export default NotificationsDropdown;
