var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { date } from 'quasar';
import { isEmpty } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let NotificationCardItemComponent = class NotificationCardItemComponent extends Vue {
    constructor() {
        super(...arguments);
        this.hovering = false;
        this.hasContext = false;
        this.defaultAvatar = this.userOrigin == ''
            ? 'statics/images/default_avatar.jpeg'
            : 'statics/images/unibot.jpeg';
    }
    mounted() {
        this.hasContext =
            !isEmpty(this.notification) && !isEmpty(this.notification.context);
        this.getUrl();
    }
    getUrl() {
        if (window.location.hostname.match('localhost'))
            this.notification.description = this.notification.description.replace(/https:\/\/app.speedio.com.br/g, 'http://localhost:8080');
        if (window.location.hostname.match('dev'))
            this.notification.description = this.notification.description.replace(/https:\/\/app.speedio.com.br/g, 'https://dev.speedio.com.br');
        if (window.location.hostname.match('test') ||
            window.location.hostname.match('staging')) {
            this.notification.description = this.notification.description.replace(/https:\/\/app.speedio.com.br/g, 'https://staging.speedio.com.br');
        }
    }
    onIgnore() {
        this.$emit('onIgnore', this.notification);
    }
    onDetails(notification) {
        this.$emit('onDetails', notification);
    }
    handleOpenDetails() {
        this.trackingOpenNotificationItem();
        this.onDetails(this.notification);
    }
    handleDownloadAction() {
        this.trackingDownloadAction();
        this.onDetails(this.notification);
    }
    onSuggestSpeedmail() {
        this.$emit('onSuggestSpeedmail');
    }
    onSuggestSailor() {
        window.open('https://wa.me/5582991265132?text=Ol%C3%A1%21+Quero+saber+mais+sobre+o+teste+gratuito+da+nova+plataforma+da+Speedio', '_blank');
    }
    trackingDownloadAction() {
        return mixpanelTracking('notificações: Baixou um arquivo CSV/Excel');
    }
    trackingOpenNotificationItem() {
        return mixpanelTracking('notificações: Abriu detalhes de uma notificação');
    }
    get isSuggestSpeedmail() {
        if (this.notification.context &&
            this.notification.context.is_suggest_speedmail)
            return true;
        return false;
    }
    get isSuggestSailor() {
        if (this.notification.context &&
            this.notification.context.is_suggest_sailor)
            return true;
        return false;
    }
    get importantNotification() {
        return this.notification.alert_type == 'important';
    }
    get isCsvOrExcel() {
        if (this.notification.context.service) {
            if (this.notification.context.service.includes('excel') ||
                this.notification.context.service.includes('csv')) {
                return true;
            }
        }
        return false;
    }
};
__decorate([
    Prop({ required: true })
], NotificationCardItemComponent.prototype, "notification", void 0);
__decorate([
    Prop({ default: '' })
], NotificationCardItemComponent.prototype, "csAvatar", void 0);
__decorate([
    Prop({ default: '' })
], NotificationCardItemComponent.prototype, "userOrigin", void 0);
NotificationCardItemComponent = __decorate([
    Component({
        name: 'NotificationCardItemComponent',
        filters: {
            formatDateBRL(value) {
                return date.formatDate(value, 'DD/MM/YYYY');
            },
        },
    })
], NotificationCardItemComponent);
export default NotificationCardItemComponent;
