var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var HelpButton_1;
import { Component, Vue, Prop } from 'vue-property-decorator';
let HelpButton = HelpButton_1 = class HelpButton extends Vue {
    mounted() {
        if (
        //@ts-ignore
        window.HubSpotConversations &&
            //@ts-ignore
            window.HubSpotConversations.widget) {
            if (this.showHelp == true) {
                //@ts-ignore
                window.HubSpotConversations.widget.load({ widgetOpen: true });
            }
            else {
                //@ts-ignore
                window.HubSpotConversations.widget.load({ widgetOpen: false });
            }
        }
    }
};
__decorate([
    Prop({ default: false })
], HelpButton.prototype, "showHelp", void 0);
HelpButton = HelpButton_1 = __decorate([
    Component({
        name: 'HelpButton',
        components: {
            HelpButton,
        },
    })
], HelpButton);
export default HelpButton;
